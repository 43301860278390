Vue.component("Maintenance", {
    props: {
        redirect: { type: String, required: false },
    },
    data: () => ({
        count: 30
    }),
    mounted() {
        if(this.redirect) this.clok();
    },
    methods: {
        clok(){
            if (this.count <= 0) return window.location = this.redirect;
            return setTimeout(() => {
                this.count -= 1;
                this.clok();
            }, 1000);
        }
    },
});