import axios from "axios";
import { maps, partnersMaps } from "../listMap";
import slugify from "slugify";
import { posthogClient } from "../../posthog/posthog-init";
const getCsrToken = () => {
    try {
        return document.getElementsByName("csrf-token")[0].content;
    } catch (err) {
        return null;
    }
};
const USER_CONNECTED_EVENT_TYPE = 'userConnected';
Vue.component("Funnel", {
    props: {
        initialuser: {
            type: Object,
            required: false,
            default: null,
        },
        mapUrl: {
            type: String,
            required: false,
            default: null,
        },
        roomUrl: {
            type: String,
            required: false,
            default: null,
        },
    },
    data: () => ({
        step: 1,
        form: new SparkForm({
            email: null,
            password: null,
            name: null,
            firstName: null,
            lastName: null,
            organization: null,
            organizationSlug: null,
            yourActivity: null,
            whichUse: null,
            world: null,
            worldSlug: null,
            mapName: null,
            mapNameSlug: null,
            mapUrl: null,
            code: null,
            terms: false,
            newsletter: false,
            plan: "free_plan",
        }),
        nb1: null,
        nb2: null,
        nb3: null,
        nb4: null,
        nb5: null,
        nb6: null,
        errors: null,
        virtualSpaceLink: "",
        privateAccessLink: null,
        windowsContextSSOConnection: null,
        testOidcEnabled: window.Spark.allowTestOidcLogin,
    }),
    mounted() {
        // define X-CSRF-TOKEN
        $.ajaxSetup({
            headers: {
                "X-CSRF-TOKEN": getCsrToken(),
            },
        });
        // push posthog capture
        posthogClient.capture("funnel_step_0");
        this.disablePlayerControls();
        if (this.initialuser == undefined || this.initialuser.email == undefined) return;
        this.init(this.initialuser);
    },
    computed: {
        step1() {
            return this.step === 1;
        },
        step2() {
            return this.step === 2;
        },
        step3() {
            return this.step === 3;
        },
        step4() {
            return this.step === 4;
        },
        step5() {
            return this.step === 5;
        },
        step6() {
            return this.step === 6;
        },
        loader() {
            return this.form.busy;
        },
        inIframe() {
            try {
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        },
    },
    methods: {
        init(user) {
            // init analytics customers
            if (user && user.id && user.email) {
                posthogClient.identifyUser(user.id, user.email);
            }
            this.form.email = user.email ?? null;
            this.form.name = user.name ?? null;
            this.form.firstName = user.first_name ?? null;
            this.form.lastName = user.last_name ?? null;
            this.form.organization = user.company_name ?? null;
            this.form.organizationSlug = user.company_name ? slugify(user.company_name).toLowerCase() : null;
            this.form.yourActivity = user.your_activity ?? null;
            this.form.whichUse = user.which_use ?? null;
            this.form.language = user.language ?? null;
            this.form.world = user.world ?? null;
            this.form.worldSlug = user.worldSlug ? slugify(user.worldSlug).toLowerCase() : null;
            if (
                this.form.world != undefined &&
                this.form.world !== "" &&
                (this.form.organization == undefined || this.form.organization === "")
            ) {
                this.form.organization = this.form.world;
                this.form.organizationSlug = slugify(this.form.world).toLowerCase();
            }
            if (user.organization) {
                this.form.organization = user.organization;
                this.form.organizationSlug = user.organizationSlug;
            }
            this.form.mapUrl = user.mapUrl ?? null;
            this.form.mapName = user.mapName ?? null;
            this.form.mapNameSlug = user.mapNameSlug ? slugify(user.mapNameSlug).toLowerCase() : null;
            const step = Number.parseInt(user.register_step_token);
            this.step = Number.isNaN(step) ? 1 : Number.parseInt(step);
            if (user.first_organization_slug) {
                this.form.organizationSlug = user.first_organization_slug;
            }
            if (user.first_world_slug) {
                this.form.worldSlug = user.first_world_slug;
            }
            if (user.first_map_slug) {
                this.form.mapNameSlug = user.first_map_slug;
            }
            /*if (user.teams && user.teams.length > 0 && user.teams[0].worlds.length) {
                this.form.organizationSlug = user.teams[0].slug;
                this.form.worldSlug = user.teams[0].worlds[0].slug;
                if (user.teams[0].worlds[0].rooms && user.teams[0].worlds[0].rooms.length > 0) {
                    this.form.mapNameSlug = user.teams[0].worlds[0].rooms[0].slug;
                }
            }*/
            this.virtualSpaceLink += `${window.Spark.frontUrl}/@/${this.form.organizationSlug}/${this.form.worldSlug}`;
            if (this.form.mapNameSlug) {
                this.virtualSpaceLink += `/${this.form.mapNameSlug}`;
            }
            if (this.step > 1) {
                this.form.terms = true;
            }
            // if there is an error on step 4 or more, we go back to step 3
            if (this.step > 3 && (this.form.mapUrl == undefined || this.form.mapName == undefined || this.form.mapNameSlug == undefined || this.form.world == undefined || this.form.worldSlug == undefined)) {
                this.step = 3;
            }
        },
        async nextStep() {
            if (this.step6) return;
            this.errors = null;
            this.form.startProcessing();
            if (this.step1) {
                try {
                    const user = await Spark.post("/funnel/connection", this.form);
                    this.init(user);
                    if (Number.isNaN(user.register_step_token)) {
                        this.step += 1;
                    }
                    //analytics client with posthug
                    try {
                        posthogClient.identifyUser(user.id, user.email);
                        posthogClient.capture("funnel_step_1", { connection_type: "email" });
                    } catch (err) {
                        console.error(err);
                    }
                } catch (err) {
                    this.form.finishProcessing();
                    console.error(err);
                    return;
                }
            } else if (this.step2) {
                try {
                    this.form.name = `${this.form.firstName} ${this.form.lastName}`;
                    this.form.organizationSlug = this.form.organization
                        ? slugify(this.form.organization).toLowerCase()
                        : null;
                    await Spark.post("/funnel/information", this.form);
                    this.step += 1;
                    //analytics client with posthug
                    try {
                        posthogClient.capture("funnel_step_2");
                    } catch (err) {
                        console.error(err);
                    }
                } catch (err) {
                    console.error(err);
                    this.form.finishProcessing();
                    return;
                }
            } else if (this.step3) {
                this.form.worldSlug = this.form.world ? slugify(this.form.world).toLowerCase() : null;
                try {
                    const user = await Spark.post("/funnel/map", this.form);
                    this.step += 1;
                    //analytics client with posthug
                    try {
                        posthogClient.capture("funnel_step_3");
                    } catch (err) {
                        console.error(err);
                    }
                    if (user.is_verified) {
                        this.form.terms = true;
                        this.form.newsletter = user.newsletter_accepted;
                        return this.nextStep();
                    }
                } catch (err) {
                    console.error(err);
                    this.form.finishProcessing();
                    return;
                }
            } else if (this.step4) {
                try {
                    this.form.code = null;
                    if (
                        this.nb1 != undefined &&
                        this.nb2 != undefined &&
                        this.nb3 != undefined &&
                        this.nb4 != undefined &&
                        this.nb5 != undefined &&
                        this.nb6 != undefined
                    ) {
                        this.form.code = `${this.nb1}${this.nb2}${this.nb3}${this.nb4}${this.nb5}${this.nb6}`;
                    }
                    if (!this.form.organization) {
                        this.form.organization = this.form.world;
                        this.form.organizationSlug = this.form.worldSlug;
                    }
                    const team = await Spark.post("/register", this.form);
                    this.form.organizationSlug = team.slug;
                    this.virtualSpaceLink = `${window.Spark.frontUrl}/@/${this.form.organizationSlug}/${this.form.worldSlug}/${this.form.mapNameSlug}`;
                    this.privateAccessLink = team.loginLink;
                    this.step += 1;
                    //analytics client with posthug
                    try {
                        posthogClient.capture("funnel_step_4");
                    } catch (err) {
                        console.error(err);
                    }
                    //play audio for step 5
                    const audio = new Audio("/audio/clapping.mp3");
                    audio.play();
                } catch (err) {
                    console.error(err);
                    this.form.finishProcessing();
                    return;
                }
            } else if (this.step5) {
                this.step += 1;
            }
            this.form.finishProcessing();
        },
        previousStep() {
            if (this.step1) return;
            this.step -= 1;
        },
        gotToStep(index) {
            if (this.step <= index || index === 0 || index > 5) return;
            this.step = index;
        },
        mapSelected({ mapUrl, mapName }) {
            this.form.mapUrl = mapUrl;
            this.form.mapName = mapName != undefined && mapName !== "" ? mapName : this.form.world;
            this.form.mapNameSlug = slugify(this.form.mapName).toLowerCase();
        },
        keyUpCode(event){
            if(event.keyCode === 8){
                this.removeNumber(Number.parseInt(event.target.dataset.index));
            }

            if(this[`nb${event.target.dataset.index}`] !== null) {
                if (this[`nb${event.target.dataset.index}`] > 9) {
                    return;
                }
                if (event.target.dataset.index != "6") {
                    this.focusInputConfirmCode(Number.parseInt(event.target.dataset.index) + 1);
                }
            }

            if(37 === event.keyCode){
                const inputNumber = Number.parseInt(event.target.dataset.index);
                if(inputNumber == 1) return;
                this.focusInputConfirmCode(inputNumber-1);
            }
            if(39 === event.keyCode){
                const inputNumber = Number.parseInt(event.target.dataset.index);
                if(inputNumber == 6) return;
                this.focusInputConfirmCode(inputNumber+1);
            }
        },
        removeNumber(initNumber){
            if(initNumber == 1){
                this[`nb${initNumber}`] = null;
                return;
            }
            this[`nb${initNumber}`] = null;
            this.focusInputConfirmCode(initNumber-1);
        },
        focusInputConfirmCode(inputName) {
            //focus next element
            const element = document.getElementById(`nb${inputName}`);
            if(element == undefined) throw new Error(`cannot find element nb${inputName}`);
            element.focus();
        },
        checkValueConfirmNumber(inputEvent) {
            const value = inputEvent.target.value;
            if (value == undefined || value === "" || !inputEvent.target.value.match(/[0-9]{6}$/)) return;
            value.split("").forEach((number, index) => {
                this[`nb${index + 1}`] = Number.parseInt(number);
            });
            this.focusInputConfirmCode(6);
        },
        disablePlayerControls() {
            //TODO fix me
            window.parent.postMessage({ type: "disablePlayerControls" }, "*");
        },
        restorePlayerControls() {
            //TODO fix me
            window.parent.postMessage({ type: "restorePlayerControls" }, "*");
        },
        goToNewWorld() {
            let url = this.privateAccessLink;
            if (!url || url === "") {
                url = this.virtualSpaceLink;
            }
            if (!this.inIframe) {
                window.location = url;
                return;
            }
            this.restorePlayerControls();
            this.userRegisteredPostMessageApi();
            window.parent.postMessage(
                {
                    type: "goToPage",
                    data: { url },
                },
                "*"
            );
        },
        close() {
            this.restorePlayerControls();
            window.parent.postMessage(
                {
                    type: "closeModal",
                },
                "*"
            );
        },
        async sendEmailCheck() {
            this.form.startProcessing();
            try {
                await Spark.post("/funnel/mail", this.form);
            } catch (err) {
                console.error(err);
            }
            this.form.finishProcessing();
        },
        contactUs() {
            window.open("https://workadventu.re/contact", "blank");
        },
        oauthLogin(id) {
            if (!this.form.terms) {
                this.form.errors.set({ terms: ["Please check our terms before to continue"] });
                return;
            }
            try {
                posthogClient.capture("funnel_step_1", 
                    { 
                        sso_id: id,
                        connection_type: "SSO" 
                    }
                );
            } catch (err) {
                console.error(err);
            }
            this.form.startProcessing();
            this.windowsContextSSOConnection = window.open(
                `/oauth/${id}/login?redirect=${encodeURI(`/funnel/auth-callback`)}`
            );
            this.checkUserConnected();
        },
        async checkUserConnected() {
            window.addEventListener('message', async (event) => {
                if (event.origin !== window.location.origin) return;
                if(event.data.type !== USER_CONNECTED_EVENT_TYPE) return;

                try {
                    //check if user is logged with SSO connection
                    const response = await axios.get(`/funnel/is-logged?newsletter=${this.form.newsletter}`);
                    this.init(response.data);
                    //close windows
                    if (this.windowsContextSSOConnection) {
                        this.windowsContextSSOConnection.close();
                    }
                    this.windowsContextSSOConnection = null;
                    window.focus();
                    this.form.finishProcessing();
                    // with SSO connection, the email step is past
                    try {
                        // push "funnel_step_4" event if user is on the step 3 or step 4
                        if(this.step3 === true || this.step4 === true) posthogClient.capture("funnel_step_4");
                    } catch (err) {
                        console.error(err);
                    }
                } catch (err) {
                    // TODO something wrong show error message to try again to connect user
                    console.info('SSO error connection', err);
                    this.form.errors.set({ connection: ["Something wrong, please try again or contact " + window.Spark.emailFromAddress] });
                    this.form.finishProcessing();
                }
            });
        },
        userRegisteredPostMessageApi() {
            window.parent.postMessage(
                {
                    id: 0,
                    query: {
                        data: {
                            key: "isRegistered",
                            persist: true,
                            public: false,
                            scope: "world",
                            ttl: undefined,
                            value: true,
                        },
                        type: "setPlayerVariable",
                    },
                },
                "*"
            );
        },
    },
});
Vue.component("InviteForm", {
    props: {
        mapUrl: String,
        email: String,
    },
    data: () => ({
        form: new SparkForm({
            mapUrl: null,
            email: null,
            emails: [
                {
                    id: Math.random().toString(36).substring(7),
                    email: "",
                },
                {
                    id: Math.random().toString(36).substring(7),
                    email: "",
                },
            ],
        }),
    }),
    mounted() {
        this.form.mapUrl = this.mapUrl;
        this.form.email = this.email;
    },
    methods: {
        addForm() {
            this.form.emails.push({ id: Math.random().toString(36).substring(7), value: "" });
        },
        removeForm(index) {
            if (index == undefined || index > this.form.emails.length - 1 || this.form.emails.length === 1)
                throw "Error to delete a form";
            this.form.emails.splice(index, 1);
        },
        copyLink() {
            const input = document.getElementById("input-share-link");
            input.focus();
            input.select();
            navigator.clipboard
                .writeText(input.value)
                .catch((err) => console.error("Navigator clipboard write text error: ", err));
        },
        async sendEmail() {
            this.form.startProcessing();
            this.$emit("startprocessing");
            try {
                await Spark.post("/funnel/invite", this.form);
                try {
                    posthogClient.capture("funnel_step_5");
                } catch (err) {
                    console.error(err);
                }
                this.form.finishProcessing();
                this.$emit("finishprocessing");
                this.$emit("gotonewworld");
            } catch (e) {
                console.error(e);
                this.form.finishProcessing();
                this.$emit("finishprocessing");
            }
        },
    },
});
Vue.component("CopyForm", {
    methods: {
        copyLink() {
            const input = document.getElementById("input-share-link");
            input.focus();
            input.select();
            navigator.clipboard
                .writeText(input.value)
                .catch((err) => console.error("Navigator clipboard write text error: ", err));
        },
    },
});
Vue.component("ListMapFunnel", {
    data: () => ({
        styleFilter: null,
        mapSelected: null,
    }),
    created() {
    },
    computed: {
        allPartnersMaps(){
            return partnersMaps;
        },
        allMaps() {
            return Object.keys(maps).reduce((tab, value) => {
                return tab.concat(
                    maps[value].reduce((accepts, obect) => {
                        accepts.push(obect);
                        return accepts;
                    }, [])
                );
            }, []);
        },
        listFiltered() {
            const value = Object.keys(maps).reduce((tab, value) => {
                return tab.concat(
                    maps[value].reduce((accepts, obect) => {
                        let cdtUser = true;
                        let cdtStyle = true;
                        let cdtUsers = true;
                        if (this.styleFilter !== "" && this.styleFilter != undefined) {
                            cdtStyle = obect.tags.includes(this.styleFilter);
                        }
                        if (cdtUser && cdtStyle && cdtUsers) {
                            accepts.push(obect);
                        }
                        return accepts;
                    }, [])
                );
            }, []);
            return value;
        },
    },
    methods: {
        selectMap(map) {
            this.mapSelected = map.mapUrl;
            this.$emit("selectedmap", map);
        },
        isSelected({ url }) {
            return this.listFiltered.find((mapSearch) => mapSearch.url === url);
        },
    },
});
Vue.component("UserLogged", {
    data: () => ({
        second: 5,
    }),
    mounted() {
        this.timer();
        window.opener.postMessage({ type: USER_CONNECTED_EVENT_TYPE }, window.location.origin);
        setTimeout(() => {
            window.close();
        }, 5000);
    },
    methods: {
        timer() {
            if (this.second === 0) return;
            setTimeout(() => {
                this.second -= 1;
                this.timer();
            }, 1000);
        },
    },
});

Vue.component("Pricing", {
    data: () => ({
        nbUser: 15,
        oneDayPricing: 4,
        fiveDaysPricing: 8,
        byMonthByYearPricing: 10,
        byMonthPricing: 15,
    }),
    mounted() {
        $('[data-toggle="tooltip"]').tooltip();
    },
    computed: {
        calculOneDay() {
            return this.nbUser * this.oneDayPricing;
        },
        calculFiveDays() {
            return this.nbUser * this.fiveDaysPricing;
        },
        calculMonth() {
            return this.nbUser * this.byMonthPricing;
        },
        calculMonthByYear() {
            return this.nbUser * this.byMonthByYearPricing;
        },
    },
    methods: {
        goToPricing(event) {
            //TODO fix me
            window.parent.postMessage({ type: "restorePlayerControls" }, "*");
            window.parent.postMessage({ type: "closeModal" }, "*");

            let plan = event.target.getAttribute("data-plan");
            let url = `https://workadventu.re/pricing?nbUser=${this.nbUser}`;
            if (plan != undefined) {
                url += `&price=${plan}`;
            }
            window.open(url);
        },
        scrollToFeature() {
            const element = document.getElementById("see-all-advantage");
            element.scrollIntoView();
        },
    },
});
